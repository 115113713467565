/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["playButton"] = {
    priority: -1001,
    type: Placements.FREE_FOCUS_MAIN,

    filter: function () {
        return !cleverapps.forces.isShown(Forces.METHA_PLAY_BUTTON.id);
    },

    action: function () {
        cleverapps.focusManager.display({
            focus: "PlayButtonForce",
            action: function (f) {
                cleverapps.playButton.showForce(f);
            }
        });
    }
};