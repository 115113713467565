/**
 * Created by vladislav on 11.02.2020
 */

var Round = function (rumble, players, winners) {
    this.rumble = rumble;
    this.players = players;
    this.id = rumble.rounds.length;

    this.winners = winners;
};

Round.prototype.changePlayerId = function (oldId, newId) {
    for (var i = 0; this.players && i < this.players.length; ++i) {
        if (this.players[i] === oldId) {
            this.players[i] = newId;
        }
    }

    for (i = 0; this.winners && i < this.winners.length; ++i) {
        if (this.winners[i] === oldId) {
            this.winners[i] = newId;
        }
    }
};

Round.prototype.isLast = function () {
    return this.id === 2;
};

Round.prototype.getOpponentId = function (id) {
    var index = this.players.indexOf(id);
    return this.players[index ^ 1];
};

Round.prototype.getOpponent = function (id) {
    var opponentId = this.getOpponentId(id);
    var opponent = this.rumble.players[opponentId];

    if (!opponent) {
        throw new Error("no opponent for player " + id + " " + opponentId
            + " rumble players: " + Object.keys(this.rumble.players).join(", ")
            + " round players: " + this.players.join(", "));
    }

    return {
        id: opponentId,
        name: opponent.name,
        avatar: opponent.avatar
    };
};

Round.prototype.calcWinners = function (outcome) {
    this.winners = this.players.filter(function (id, pos) {
        return pos % 2 === 0;
    }).map(function (player1) {
        var player2 = this.getOpponent(player1).id;

        if (player1 === connector.platform.getUserID() || player2 === connector.platform.getUserID()) {
            if (outcome === GameBase.OUTCOME_VICTORY) {
                return connector.platform.getUserID();
            }

            return (player1 === connector.platform.getUserID()) ? player2 : player1;
        }

        return Math.random() < 0.5 ? player1 : player2;
    }, this);
};

Round.prototype.isWinner = function (playerId) {
    return this.winners.indexOf(playerId) !== -1;
};

Round.prototype.getInfo = function () {
    return {
        players: this.players,
        winners: this.winners
    };
};