/**
 * Created by olga on 27.01.2025
 */

var PhotosTutorial = function () {
    return new TutorialScenario(TutorialScenario.BASIC_TUTORIAL_NAME, {
        listSteps: function () {
            return [PhotosTutorial.createStep()];
        },
        isAvailable: function () {
            return Game.currentGame && !cleverapps.forces.isShown(Forces.KEYPAD.id);
        }
    });
};

PhotosTutorial.createStep = function () {
    var scene = cleverapps.scenes.getRunningScene();
    var options = {
        name: "discover",
        onStart: function () {
            scene.photos.toggleBlur(false);
            var game = Game.currentGame;
            var force = Forces.KEYPAD;

            var area = game.getUndiscoveredAreas().sort(function (a, b) {
                return b.y - a.y;
            })[0];

            force.finger = function () {
                return {
                    path: [area]
                };
            };

            this.force = cleverapps.forces.create(scene.photos, force);
        },

        onExecution: function () {
            this.force.hideForceHints();
        },

        onFinish: function () {
            scene.playersView.stopForceAnimation();
            if (this.force && cleverapps.forces.getRunningForce() === this.force) {
                cleverapps.forces.closeRunningForce();
            }
        }
    };

    return new TutorialStep(options);
};
