/**
 * Created by vladislav on 17.09.2020
 */

var SimpleLocation = function (locationId) {
    BaseLocation.call(this, locationId);
};

SimpleLocation.prototype = Object.create(BaseLocation.prototype);
SimpleLocation.prototype.constructor = SimpleLocation;

SimpleLocation.prototype.load = function (stored) {
    this.progress = stored.progress || 0;
    this.pendingProgress = 0;
};

SimpleLocation.prototype.getInfo = function () {
    return {
        progress: this.progress
    };
};

SimpleLocation.prototype.changeProgress = function (progress) {
    this.pendingProgress += progress;

    this.setProgress(this.progress + progress);
};

SimpleLocation.prototype.setProgress = function (progress) {
    this.progress = progress;
    this.save();

    cleverapps.meta.trigger("progressChanged");
};

SimpleLocation.prototype.getProgress = function () {
    return this.progress;
};

SimpleLocation.prototype.getPendingProgress = function () {
    return this.pendingProgress;
};

SimpleLocation.prototype.getRequiredProgress = function () {
    return SimpleLocation.GetRequiredProgress(this.locationId);
};

SimpleLocation.prototype.isCompleted = function () {
    return this.getProgress() >= this.getRequiredProgress();
};

SimpleLocation.prototype.gamePlayed = function (outcome, game) {
    if (outcome === GameBase.OUTCOME_VICTORY) {
        game.rewards.locationProgress = this.amountLevelStars(game.level);
    }
};

SimpleLocation.prototype.amountLevelStars = function (level) {
    if (level.isHard()) {
        return 3;
    }

    if (level.isTricky()) {
        return 2;
    }

    return 1;
};

SimpleLocation.prototype.calcBundles = function () {
    return [
        this.getBackgroundBundle(),
        this.getIconBundle(this.locationId),
        this.getIconBundle(this.locationId + 1),
        this.getPostMoveActionBundle()
    ].filter(Boolean);
};

SimpleLocation.prototype.getIconBundle = function (index) {
    if (index >= cleverapps.meta.listAvailableLocations().length || cleverapps.config.rpg) {
        return "simple_background_icon_coming_soon";
    }

    return "simple_background_icon_" + index;
};

SimpleLocation.prototype.getBackgroundBundle = function () {
    if (cleverapps.config.rpg) {
        return;
    }

    return [
        "simple_background_" + this.locationId,
        (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? "simple_background_vertical_" : "simple_background_horizontal_") + this.locationId,
        "simple_background_" + cleverapps.config.orientation + "_" + this.locationId
    ].find(function (bundleName) {
        return bundles[bundleName];
    });
};

SimpleLocation.prototype.getPostMoveActionBundle = function () {
    if (cleverapps.config.type === "tile3") {
        if (TileGenerator.getNewSkins().length) {
            return "tiles_unlock_window";
        }
    }
};

SimpleLocation.prototype.animateProgressChange = function (f) {
    this.trigger("animateProgressChange", this.pendingProgress, f);
    this.pendingProgress = 0;
};

SimpleLocation.prototype.isForceAvailable = function () {
    return cleverapps.user.episode === 0 && !cleverapps.forces.isShown(Forces.SIMPLE.id) && cleverapps.config.type !== "match3";
};

SimpleLocation.prototype.showForce = function (f) {
    this.trigger("showForce");
    cleverapps.forces.onceForceClosed = f;
};

SimpleLocation.prototype.calcReward = function (splitToRewards) {
    var rewards = cleverapps.config.soft ? { soft: 50 } : { hard: 50 };
    rewards.boosters = {};

    var found = 0;
    var boosters = cleverapps.boosters.listBoostersBefore();
    if (cleverapps.config.name === "differences") {
        boosters = cleverapps.boosters.listBoosters();
    }

    boosters.forEach(function (booster) {
        if (found < 3 && booster.isAvailable()) {
            rewards.boosters[booster.id] = 1;
            found++;
        }
    });

    if (splitToRewards) {
        var rewardsList = [];
        for (var type in rewards) {
            var reward = rewards[type];
            if (type === "boosters") {
                rewardsList = rewardsList.concat(Object.keys(reward).map(function (id) {
                    return {
                        type: "boosters",
                        value: {
                            id: id,
                            amount: rewards.boosters[id]
                        }
                    };
                }));
            } else {
                rewardsList.push({ type: type, value: reward });
            }
        }
        return rewardsList;
    }

    return rewards;
};

SimpleLocation.GetRequiredProgress = function (locationId) {
    var stars = [
        5, 10, 20, 30, 30, 40, 40, 40, 40, 40,
        50, 50, 50, 50, 50, 70, 70, 70, 70, 70,
        100, 100, 100, 100, 100, 125, 125, 125, 125, 125,
        150, 150, 150, 150, 150, 175, 175, 175, 175, 175,
        200, 200, 200, 200, 200, 225, 225, 225, 225, 225
    ];

    if (cleverapps.config.rpg) {
        stars = [5, 10, 15, 20, 25];
    }

    if (locationId < stars.length) {
        return stars[locationId];
    }

    return stars[stars.length - 1];
};
