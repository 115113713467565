/**
 * Created by mac on 7/26/18
 */

var MissionWindowOld = MissionWindow;

var MissionWindow = FeastMissionWindow.extend({
    onWindowLoaded: function (mission) {
        this._super({
            mission: mission,
            name: mission.getName() + "_missionwindow",
            title: "Missions." + mission.getName() + ".Title",
            finishedText: "Missions.windowFinished",
            bundleName: "mission_window",
            notCloseByTouchInShadow: true,
            rowConstructor: MissionRow,
            scoreTooltip: false
        });

        Mission.logEvent(cleverapps.EVENTS.MISSION_OPEN, this.mission.getName());
    },

    getGuideOptions: function () {
        return GuideWindow.getMissionRulesWindowOptions(this.mission);
    },

    listBundles: function () {
        return ["mission_window"];
    }
});

MissionWindow.getTableRowIcon = MissionWindowOld.getTableRowIcon;
MissionWindow.getTableRowIconAnimation = MissionWindowOld.getTableRowIconAnimation;

MissionWindowOld = undefined;
