/**
 * Created by vladislav on 19.02.2020
 */

var KnockoutPlayersView = cc.Node.extend({
    ctor: function (competition, round) {
        this._super();

        this.competition = competition;

        this.setAnchorPoint(0.5, 0.5);
        this.updateSize();

        this.createBg();

        this.user = competition.player;
        this.opponent = competition.getPlayerById(round.getOpponentId(connector.platform.getUserID()));

        this.userView = new KnockoutPlayerView(this.user, this.getContentSize());
        this.addChild(this.userView);

        this.opponentView = new KnockoutPlayerView(this.opponent, this.getContentSize());
        this.addChild(this.opponentView);

        this.userView.opponentView = this.opponentView;
        this.opponentView.opponentView = this.userView;

        this.setupChildren();

        this.createWarning();

        this.competition.onChangeResultsListeners.playersView = this.createListener(this.resultsChanged.bind(this));
        this.competition.onGetOpponent = this.createListener(function () {
            return this.opponent;
        }.bind(this));
        this.competition.onGetUserView = this.createListener(function () {
            return this.userView;
        }.bind(this));
        Game.currentGame.on("stop", this.clearWarning.bind(this), this);
    },

    updateSize: function () {
        var styles = cleverapps.styles.KnockoutPlayersView;
        this.setContentSize2(styles.size);
    },

    setupChildren: function () {
        this.userView.setPositionRound(this.getViewPos(this.userView));
        this.opponentView.setPositionRound(this.getViewPos(this.opponentView));
    },

    getViewPos: function (view) {
        var styles = cleverapps.styles.KnockoutPlayersView.views;
        return view.player.player ? styles.left : styles.right;
    },

    createWarning: function () {
        var styles = cleverapps.styles.KnockoutPlayersView.warning;
        if (!styles) {
            return;
        }

        this.warning = new cleverapps.Spine(bundles.game.jsons.tournament_warning_json);
        this.addChild(this.warning);
        this.warning.setPositionRound(this.width / 2 + styles.x, this.height / 2 + styles.y);
        this.warning.setStartVisibleListener(function () {
            this.warning.setVisible(false);
        }.bind(this));
        this.warning.setAnimation(0, "animation", true);
    },

    clearWarning: function () {
        this.userView.stopUserBehindAnimation();
        this.toggleWarning(false);
    },

    toggleWarning: function (visible) {
        this.warning.setVisible(visible);
    },

    resultsChanged: function (id) {
        var userPoints = this.user.amount;
        var opponentPoints = this.opponent.amount;
        var totalPoints = this.competition.options.algo.maxResult;

        if (id === this.user.id) {
            this.userView.checkPlayerPointOpened();
            if (userPoints === opponentPoints + 1) {
                cleverapps.audio.playSound(bundles.game.urls.player_overtook_effect);
            }
        } else {
            this.opponentView.checkPlayerPointOpened();

            if (opponentPoints === userPoints + 1) {
                cleverapps.audio.playSound(bundles.game.urls.enemy_overtook_effect);
            }
            if (opponentPoints + 1 === totalPoints) {
                cleverapps.audio.playSound(bundles.game.urls.enemy_last_word_effect);
            }
        }

        var isVisible = userPoints < opponentPoints || (userPoints + 1 === totalPoints && opponentPoints + 1 === totalPoints);

        if (isVisible) {
            this.userView.runUserBehindAnimation();
        } else {
            this.userView.stopUserBehindAnimation();
        }

        this.toggleWarning(isVisible);
    },

    runForceAnimation: function () {
        this._forceAnimationRunning = true;

        var animation = function () {
            if (!this._forceAnimationRunning) {
                return;
            }

            this.player = new ActionPlayer([
                function (f) {
                    this.userView.runForceAvatarAnimation(f);
                }.bind(this),
                function (f) {
                    this.opponentView.runForceAvatarAnimation(f);
                }.bind(this),
                function (f) {
                    this.userView.runForceWordsAnimation(f);
                }.bind(this),
                function (f) {
                    this.opponentView.runForceWordsAnimation(function () {
                        f();
                        animation();
                    });
                }.bind(this)
            ]);

            this.player.play();
        }.bind(this);

        animation();
    },

    stopForceAnimation: function () {
        this._forceAnimationRunning = false;
        this.player.stop();
        this.userView.stopAllActions();
        this.opponentView.stopAllActions();
    },

    createBg: function () {
        var image = bundles.game.frames.tournament_board_bg_png;
        if (cleverapps.isRumble() && cleverapps.knockoutGame.rumble.getCurrentRound().isLast()) {
            image = bundles.game.frames.tournament_board_bg_hard_png;
        }

        this.bg = new cc.Sprite(image);
        this.addChild(this.bg);
        this.bg.setPositionRound(this.width / 2, this.height / 2 + cleverapps.styles.KnockoutPlayersView.bgOffset);
    }
});

cleverapps.styles.KnockoutPlayersView = {
    size: {
        width: 990,
        height: 125
    },

    views: {
        left: {
            x: { align: "center" },
            y: { align: "center" }
        },
        right: {
            x: { align: "center" },
            y: { align: "center" }
        }
    },
    warning: {
        x: 0,
        y: -36
    },
    finger: {},
    bgOffset: -8
};
