/**
 * Created by olga on 27.01.2025
 */

var KnockoutTutorial = function () {
    return new TutorialScenario("knockout", {
        priority: 1,
        listSteps: function () {
            return [KnockoutTutorial.createStep()];
        },
        isAvailable: function () {
            return Game.currentGame && !cleverapps.forces.isShown(Forces.KNOCKOUT_PLAYERS.id);
        }
    });
};

KnockoutTutorial.createStep = function () {
    var scene = cleverapps.scenes.getRunningScene();
    var options = {
        name: "tooltip",
        onStart: function () {
            cleverapps.focusManager.showControlsWhileFocused("players");
            scene.playersView.runForceAnimation();
            this.force = cleverapps.forces.create(scene.playersView, Forces.KNOCKOUT_PLAYERS, {
                highlights: [scene.boardView && scene.boardView.bgTop, scene.movingNode].filter(Boolean)
            });
            cleverapps.forces.onceForceClosed = function () {
                this.execute();
                Game.currentGame.counter.trigger();
            }.bind(this);
        },

        onExecution: function () {
            this.force.hideForceHints();
        },

        onFinish: function () {
            scene.playersView.stopForceAnimation();
            if (this.force && cleverapps.forces.getRunningForce() === this.force) {
                cleverapps.forces.closeRunningForce();
            }
        }
    };

    return new TutorialStep(options);
};
