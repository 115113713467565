/**
 * Created by mac on 2/25/20
 */

var DifferenceArea = function (area) {
    this.x = area.x;
    this.y = area.y;

    this.width = area.width + DifferenceArea.PADDING;
    this.height = area.height + DifferenceArea.PADDING;
    this.discovered = false;

    this.hintInProgress = false;
    this.terminateHintListeners = [];

    this.hinted = function () {
        this.hintInProgress = true;
    };

    this.terminateHints = function () {
        this.hintInProgress = false;
        this.terminateHintListeners.forEach(function (listener) {
            listener();
        });
        this.terminateHintListeners = [];
    };

    this.contains = function (point) {
        var areaData = PhotoViews.FrameSizeAndPos(this);
        return Math.abs(areaData.x - point.x) < areaData.width / 2 && Math.abs(areaData.y - point.y) < areaData.height / 2;
    };

    var margin = -3;
    var leftMargin = this.x - this.width / 2;
    var rightMargin = Differences.PHOTO_SIZE.width - (this.x + this.width / 2);
    var bottomMargin = this.y - this.height / 2;
    var topMargin = Differences.PHOTO_SIZE.height - (this.y + this.height / 2);

    if (leftMargin < margin) {
        this.x += margin - leftMargin;
        this.width -= (margin - leftMargin) / 2;
    } else if (rightMargin < margin) {
        this.x -= margin - rightMargin;
        this.width -= (margin - rightMargin) / 2;
    }
    if (bottomMargin < margin) {
        this.y += margin - bottomMargin;
        this.height -= (margin - bottomMargin) / 2;
    } else if (topMargin < margin) {
        this.y -= margin - topMargin;
        this.height -= (margin - topMargin) / 2;
    }
};

DifferenceArea.prototype.getKey = function () {
    return this.x + "_" + this.y;
};

var Differences = function (level, options) {
    this.onMistakeBoosterListeners = {};

    GameBase.call(this, level, options);

    this.areas = level.content.differences.map(function (item) {
        var area = new DifferenceArea(item);
        if (this.savedGame.discovered) {
            area.discovered = this.savedGame.discovered.indexOf(area.getKey()) !== -1;
        }
        return area;
    }.bind(this));

    this.inflamer = new Inflamer();
    this.inflamer.onBurst = this.block.bind(this);

    var competitionOptions = Competition.optionsForKnockoutGame(this);
    competitionOptions.playerResult = this.countDiscovered();

    this.competition = new Competition(competitionOptions);
    this.on("beforeStartActions", this.competition.start.bind(this.competition), this);
    this.competition.onLoseListener = this.lose.bind(this);

    this.startTime = Date.now();

    this.createBoosters();
    this.placeClovers();

    this.on("outcome", function (outcome) {
        if (outcome === GameBase.OUTCOME_VICTORY) {
            var oneDifferenceTime = Math.floor((Date.now() - this.startTime) / this.totalItemsToSolve() / 1000);
            cleverapps.competitionStatistics.saveOneDifferenceFindTime(oneDifferenceTime);
        }
    }.bind(this), this);
};

var Game = Differences;

Differences.prototype = Object.create(GameBase.prototype);
Differences.constructor = Differences;

Differences.prototype.placeClovers = function () {
    this.clovers = [];

    var cloverSize = 40;
    var occupiedAreas = this.areas.concat();

    function randomPos() {
        return {
            x: cleverapps.Random.random(cloverSize, Differences.PHOTO_SIZE.width - cloverSize),
            y: cleverapps.Random.random(cloverSize, Differences.PHOTO_SIZE.height - cloverSize) 
        };
    }

    function intersects(pos) {
        return occupiedAreas.some(function (area) {
            var left = area.x - area.width / 2 - cloverSize;
            var right = area.x + area.width / 2 + cloverSize;
            var bottom = area.y - area.height / 2 - cloverSize;
            var top = area.y + area.height / 2 + cloverSize;

            return left < pos.x && right > pos.x && bottom < pos.y && top > pos.y;
        });
    }

    var targetAmount = cleverapps.Random.random(3, 6);
    for (var i = 0; i < 10000; i++) {
        var pos = randomPos();
        if (!intersects(pos)) {
            occupiedAreas.push({
                x: pos.x,
                y: pos.y,
                width: cloverSize,
                height: cloverSize
            });

            this.clovers.push(pos);
            if (this.clovers.length === targetAmount) {
                break;
            }
        }
    }
};

Differences.prototype.createBoosters = function () {
    var discoverBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_DISCOVER);
    if (discoverBooster.isAvailable()) {
        this.discoverBooster = discoverBooster;
    }

    var brushBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_BRUSH);
    if (brushBooster.isAvailable()) {
        brushBooster.init();
        this.brushBooster = brushBooster;
    }
};

Differences.prototype.showPaint = function () {
    cleverapps.userStatus.reportUserAction();
    this.trigger("paint");
};

Differences.prototype.getAreasWithoutHint = function () {
    return this.getUndiscoveredAreas().filter(function (area) {
        return !area.hintInProgress;
    });
};

Differences.prototype.boosterDiscover = function (options) {
    options = options || {};
    cleverapps.userStatus.reportUserAction();

    var areasWithoutHint = this.getAreasWithoutHint();
    if (areasWithoutHint.length > 0) {
        var randomArea = cleverapps.Random.choose(areasWithoutHint);
        this.trigger("booster_discover", { area: randomArea, startPos: options.startPos });
    }
};

Differences.prototype.block = function () {
    if (this.inflamer.stage <= Differences.ALLOWED_MISTAKES) {
        this.trigger("block");
    } else {
        this.lose();
    }
};

Differences.prototype.getImage = function (index) {
    var name = this.level.getImageBundle();
    return bundles[name].urls[index];
};

Differences.prototype.stop = function () {
    GameBase.prototype.stop.call(this);

    this.inflamer.stop();
    this.competition.stop();
};

Differences.prototype.mistake = function (image, point) {
    this.inflamer.add();

    this.trigger("mistake", image, point);
    Object.values(this.onMistakeBoosterListeners).forEach(function (listener) {
        listener();
    });
};

Differences.prototype.clickPoint = function (image, point) {
    this.begin(true);

    var areas = this.areas.filter(function (area) {
        return area.contains(point);
    }, this);

    cleverapps.userStatus.reportUserAction();

    if (areas.length === 0) {
        this.mistake(image, point);
    } else {
        this.discover(areas[0], image, point);
    }
};

Differences.prototype.discover = function (area, image, point) {
    if (area.discovered) {
        return;
    }

    area.terminateHints();
    area.discovered = true;

    this.score.addPoints(50);

    var discovered = this.countDiscovered();
    if (discovered === this.areas.length) {
        this.win();
    }

    var tutorialStep = cleverapps.tutorial.getCurrentActiveStep();
    if (tutorialStep && tutorialStep.name === "discover") {
        tutorialStep.execute();
    }

    this.counter.trigger();

    this.trigger("discover", area, image, point, function () {
        this.competition.updatePlayerResult(connector.platform.getUserID(), discovered);
    }.bind(this));
};

Differences.prototype.countDiscovered = function () {
    return this.getDiscoveredAreas().length;
};

Differences.prototype.listDifferences = function () {
    return this.areas;
};

Differences.prototype.getDailyCupStars = function () {
    return this.areas.length;
};

Differences.prototype.showStartGameMessage = function (f, silent) {
    if (silent) {
        f();
        return;
    }

    var message = cleverapps.knockoutGame.rumble.getCurrentRound().isLast() ? "message.lastRound"
        : Messages.get("message.round", { roundId: cleverapps.knockoutGame.rumble.getCurrentRound().id + 1 });

    this.showMessage(message, f);
};

Differences.prototype.getUndiscoveredAreas = function () {
    return this.areas.filter(function (area) {
        return !area.discovered;
    });
};

Differences.prototype.getDiscoveredAreas = function () {
    return this.areas.filter(function (area) {
        return area.discovered;
    });
};

Differences.prototype.getPercentOfCompletion = function () {
    return this.countDiscovered() / this.areas.length;
};

Differences.prototype.totalItemsToSolve = function () {
    return this.listDifferences().length;
};

Differences.prototype.getInfo = function () {
    var info = GameBase.prototype.getInfo.call(this);

    info.discovered = this.getDiscoveredAreas().map(function (area) {
        return area.getKey();
    });

    return info;
};

if (cleverapps.config.debugMode) {
    Differences.prototype.discoverDebug = function (player) {
        if (player.player) {
            var hiddenAreas = this.getUndiscoveredAreas();
            if (hiddenAreas.length > 0) {
                this.discover(hiddenAreas[0]);
            }
        } else {
            this.competition.updatePlayerResult(player.id, player.amount + 1);
        }
    };
}

Differences.ALLOWED_MISTAKES = 2;
Differences.BLOCK_DURATIONS = {
    1: "10 seconds",
    2: "20 seconds"
};

Differences.PHOTO_SIZE = {
    height: 600,
    width: 900
};

DifferenceArea.PADDING = 25;